import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { Menu, X } from 'lucide-react';

const Navbar = ({ toggleContact }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navItems = [
    { name: 'Home', to: 'home' },
    { name: 'Faith', to: 'faith' },
    { name: 'About', to: 'about' },
    { name: 'Events', to: 'events' },
    { name: 'Contact Us', to: 'contact' },
  ];

  return (
    <nav
      className={`fixed w-full z-50 transition-all duration-300 ${
        isScrolled ? 'bg-gradient-to-r from-gray-900 via-teal-700 to-gray-900 shadow-lg' : 'bg-transparent'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16 sm:h-20">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link
              to="home"
              smooth={true}
              duration={500}
              offset={-70}
              className="text-3xl font-bold text-white tracking-wide cursor-pointer"
            >
              Rehoboth
            </Link>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:block">
            <div className="ml-10 flex items-center space-x-8">
              {navItems.map((item) =>
                item.to === 'contact' ? (
                  <button
                    key={item.name}
                    onClick={toggleContact}
                    className="relative group cursor-pointer text-white text-lg font-medium transition-colors duration-300 hover:text-teal-400"
                  >
                    {item.name}
                  </button>
                ) : (
                  <Link
                    key={item.name}
                    to={item.to}
                    smooth={true}
                    duration={500}
                    offset={-70}
                    className="relative group cursor-pointer text-white text-lg font-medium transition-colors duration-300 hover:text-teal-400"
                  >
                    {item.name}
                  </Link>
                )
              )}
            </div>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="focus:outline-none p-2 rounded-md text-white"
              aria-label="Toggle menu"
            >
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu Overlay */}
      <div
        className={`md:hidden fixed inset-0 bg-gray-900 bg-opacity-90 transition-all duration-500 ease-in-out ${
          isOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
        } overflow-hidden z-40`}
      >
        <div className="flex flex-col items-center justify-center h-full space-y-6">
          {navItems.map((item) =>
            item.to === 'contact' ? (
              <button
                key={item.name}
                onClick={() => {
                  toggleContact();
                  setIsOpen(false);
                }}
                className="text-white text-2xl font-semibold transition-colors duration-300 hover:text-teal-400"
              >
                {item.name}
              </button>
            ) : (
              <Link
                key={item.name}
                to={item.to}
                smooth={true}
                duration={500}
                offset={-50}
                className="text-white text-2xl font-semibold transition-colors duration-300 hover:text-teal-400"
                onClick={() => setIsOpen(false)}
              >
                {item.name}
              </Link>
            )
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;