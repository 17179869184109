import React, { useRef, useEffect, useState } from 'react';
import { Mail, MapPin, Phone, ChevronUp, ChevronDown, CheckCircle, Copy } from 'lucide-react';

const Contact = ({ isExpanded, setIsExpanded }) => {
  const [copiedText, setCopiedText] = useState('');
  const contentRef = useRef(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isExpanded ? `${contentRef.current.scrollHeight}px` : '0px';
    }
  }, [isExpanded]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopiedText(text);
        setTimeout(() => setCopiedText(''), 2000);
      })
      .catch(err => console.error('Error copying text:', err));
  };

  const CopyIndicator = ({ isCopied }) => (
    <div className="flex items-center space-x-1">
      {isCopied ? (
        <CheckCircle className="w-4 h-4 text-green-400" />
      ) : (
        <Copy className="w-4 h-4 text-gray-400 group-hover:text-teal-400" />
      )}
    </div>
  );

  return (
    <section
      id="contact"
      className={`fixed bottom-0 w-full bg-gray-900 text-white rounded-t-3xl transition-all duration-500 ease-in-out z-50
        ${isExpanded ? 'shadow-lg' : 'shadow-sm'}`}
    >
      {/* Toggle Button */}
      <div 
        className="flex items-center justify-center py-2 cursor-pointer"
        onClick={toggleExpand}
      >
        <div className="w-12 h-1 bg-gray-500 rounded-full"></div>
        {isExpanded ? (
          <ChevronDown className="w-6 h-6 ml-2 transition-transform duration-300" />
        ) : (
          <ChevronUp className="w-6 h-6 ml-2 transition-transform duration-300" />
        )}
      </div>

      {/* Contact Details */}
      <div 
        ref={contentRef}
        className="overflow-hidden transition-all duration-500 ease-in-out"
      >
        <div className="px-6 pb-6 py-1 sm:pb-2">
          <h2 className="text-lg font-semibold mb-4 text-center">Get In Touch</h2>
          <div className="flex flex-col items-center space-y-4 text-sm sm:text-base">
            
            {/* Email */}
            <div className="flex items-center space-x-2">
              <Mail className="w-5 h-5 text-teal-400" />
              <a href="mailto:info@rehobothchurch.com" className="text-sm text-gray-300 hover:text-white transition">
                info@rehobothchurch.com
              </a>
            </div>

            {/* Address */}
            <div 
              className="group flex items-center space-x-2 cursor-pointer bg-gray-800 px-4 py-2 rounded-lg hover:bg-gray-700 transition-all duration-300"
              onClick={() => copyToClipboard('185 rue des Pyrénées, 75020 Paris')}
            >
              <MapPin className="w-5 h-5 text-teal-400" />
              <address className="text-sm text-gray-300 group-hover:text-white transition not-italic">
                185 rue des Pyrénées, 75020 Paris
              </address>
              <CopyIndicator isCopied={copiedText === '185 rue des Pyrénées, 75020 Paris'} />
            </div>

            {/* Phone Numbers */}
            <div className="flex flex-col items-center space-y-2">
              <div className="flex items-center space-x-2">
                <Phone className="w-5 h-5 text-teal-400" />
                <span className="text-gray-300">Call us now 😁</span>
              </div>
              <div className="flex flex-col items-center sm:flex-row sm:space-x-4 space-y-2 sm:space-y-0">
                {['+33 7 69 93 33 75', '+33 6 28 68 73 62', '+33 6 46 10 64 55'].map((phone) => (
                  <div
                    key={phone}
                    onClick={() => copyToClipboard(phone)}
                    className="group relative flex items-center space-x-2 cursor-pointer bg-gray-800 px-4 py-2 rounded-lg hover:bg-gray-700 transition-all duration-300"
                  >
                    <span className="text-sm text-gray-300 group-hover:text-white transition">
                      {phone}
                    </span>
                    <CopyIndicator isCopied={copiedText === phone} />
                  </div>
                ))}
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;