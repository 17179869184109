//import logo from './logo.svg';
import './App.css';
import React, { useState } from 'react';
import Navbar from './components/Nav.jsx';
import Hero from './components/HeroPage.jsx';
import StatementOfFaith from './components/SOF.jsx';
import AboutPage from './components/AboutPage.jsx';
import ContactPage from './components/Contact.jsx';
import EventsSection from './components/Events.jsx';
import Footer from './components/Footer.jsx';

const App = () => {
  const [isContactOpen, setIsContactOpen] = useState(false);

  const toggleContact = () => {
    setIsContactOpen(!isContactOpen);
  };

  return (
    <div className="bg-gray-900 text-gray-200">
      {/* Navbar */}
      <Navbar toggleContact={toggleContact} />

      {/* Hero Section */}
      <Hero />

      {/* Statement of Faith Section */}
      <StatementOfFaith />

      {/* About Section */}
      <AboutPage />

      {/* Events Section */}
      <EventsSection />

      {/* Footer */}
      <Footer />

      {/* Contact Section */}
      <ContactPage isExpanded={isContactOpen} setIsExpanded={setIsContactOpen} />
    </div>
  );
};

export default App;




